import React from "react";
import styled from "styled-components";
import tw from "tailwind.macro";

import { Section, SectionWrapper } from "./components/Section";
import { Countdown } from "./components/Countdown";
import "./index.css";
import { ShareBar } from "./components/ShareBar";

const AppStyles = styled.div.attrs((p) => ({
  ...p,
  id: "app-container",
  className: `flex flex-col items-stretch px-4`,
}))`
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  & > ${SectionWrapper} {
    scroll-snap-align: start;
  }

  & a {
    ${tw`text-indigo-500`}
    white-space: nowrap;
    font-weight: bold;
  }

  & a:hover {
    text-decoration: underline;
  }
`;

function App() {
  const wrapperRef = React.useRef(null);

  React.useEffect(() => {
    const hash = document.location.hash.replace("#", "");
    const element = document.getElementById(hash);
    if (hash == null || element == null || wrapperRef == null) {
      return;
    }
    // @ts-ignore
    wrapperRef.current.scrollTo({ top: element.getBoundingClientRect().top });
  }, [wrapperRef.current]);

  return (
    <AppStyles ref={wrapperRef}>
      <Section
        id="start"
        nextId="tienpito"
        title="8 miljardia"
        description={
          <>
            Autoilijat maksavat Suomeen vuosittain <b>kahdeksan miljardia</b>{" "}
            euroa veroina.
          </>
        }
      ></Section>
      <Section
        id="tienpito"
        nextId="nettomaksu"
        title="Tienpitoon miljardi"
        description={
          <>
            Autoliiton mukaan tästä summasta tienpitoon palaa kuitenkin{" "}
            <a
              href="https://www.autoliitto.fi/tietoa-tienkayttajalle/vaalit"
              target="_blank"
            >
              vain miljardi
            </a>
            .
          </>
        }
      />
      <Section
        id="nettomaksu"
        nextId="verotuotto"
        showTitleDesc
        title={
          <>
            <Countdown from={8} to={7} inMs={800} shortDelay /> miljardia
          </>
        }
        description={
          <>
            Lähes 7 miljardia nettomaksua. Autoilija näyttää olevan todellinen
            nettomaksaja. Nyt perataan asia juurta jaksain.
          </>
        }
      />
      <Section
        id="verotuotto"
        nextId="alv"
        title="Verotuotto"
        description={
          <>
            Autoalan tiedotuskeskuksen{" "}
            <a
              href="http://www.aut.fi/etusivu_vanha/tilastot/verotus_ja_hintakehitys/valtion_verotulot_tieliikenteesta"
              target="_blank"
            >
              laskelmien mukaan
            </a>{" "}
            kahdeksan miljardia jakautuu seuraavalla tavalla. <br /> <br />
            Paina kenttiä nähdäksesi lisätietoa
          </>
        }
      >
        <ShareBar
          items={[
            {
              value: 3269,
              title: "Alv, vakuutusverot",
              description:
                "Arvonlisävero kerätään jokaisesta Suomessa ostetusta tavarasta, muun muassa ruoasta.",
            },
            {
              value: 2635,
              title: "Polttoainevero",
              description:
                "Polttoainevero on kiinteä vero polttoaineiden hinnassa.",
            },
            {
              value: 1150,
              title: "Ajoneuvovero",
              description: "Ajoneuvovero kerätään vuosittain ajoneuvoista.",
            },
            {
              value: 892,
              title: "Autovero",
              description: "Autovero kerätään uusista autoista ostohetkellä.",
            },
          ]}
        />
      </Section>
      <Section
        id="alv"
        nextId="alv2"
        title="Alv"
        description="Laskelmissa on laskettu mukaan arvonlisävero sekä vakuutusverot. Arvonlisäveroa maksetaan muun muassa ruoista, vaatteista sekä asumisesta. Vakuutusveroa maksetaan kaikista vakuutuksista, esimerkiksi kotivakuutuksesta"
      ></Section>
      <Section
        id="alv2"
        nextId="nettomaksu2"
        description={
          <>
            Vähennetään Alv laskelmista. Tämä siksi, jotta näemme, verotetaanko
            autoilua nimenomaan erityisen paljon, kuten Autoliitto antaa
            ymmärtää.
          </>
        }
      ></Section>
      <Section
        id="nettomaksu2"
        nextId="rankaisuverot"
        showTitleDesc
        description={
          <>
            Edessäsi oleva lukema on autoilun erikoisverotuksen määrä josta on
            vähennetty valtion tienpitoon laittama raha
            <br />
            <br />
            Eli niin sanotusti rankaisuvero
          </>
        }
        title={
          <>
            <Countdown from={7} to={3.7} inMs={1500} shortDelay /> miljardia
          </>
        }
      />
      <Section
        id="rankaisuverot"
        nextId="kuolemat"
        description="Nyt kun rankaisuverojen määrä on selvillä, tarkastellaan autoilusta aiheutuvia kustannuksia"
      />
      <Section
        id="kuolemat"
        nextId="nettomaksu3"
        title="Kuolemat"
        description={
          <>
            <a
              style={{ whiteSpace: "initial" }}
              target="_blank"
              href="http://liikennejarjestelma.fi/talous-ja-tehokkuus/kustannukset/liikenteen-ulkoiset-kustannukset/"
            >
              Liikenne- ja viestintäministeriön mukaan
            </a>{" "}
            tieliikenteen aiheuttamien kuolemien sekä loukkaantumisien hinta
            yhteiskunnalle on vuodessa <b>1.9 miljardia euroa</b>.
          </>
        }
      ></Section>
      <Section
        id="nettomaksu3"
        nextId="sairaudet"
        showTitleDesc
        title={
          <>
            <Countdown from={3.7} to={1.8} inMs={800} shortDelay /> miljardia
          </>
        }
      />
      <Section
        id="sairaudet"
        nextId="nettomaksu4"
        title="Sairaudet"
        description={
          <>
            Vaikka moottoriteknologia on kehittynyt vuosien varrella,
            kaupungeissa autoilu sairastuttaa ihmisiä. Autoliikenne jauhaa
            asfalttia kivipölyksi. Ihmiset sairastuvat katupölyn ohella
            hiukkasiin.
            <br />
            <br />
            Hinta yhteiskunnalle{" "}
            <a
              style={{ whiteSpace: "initial" }}
              href="http://liikennejarjestelma.fi/talous-ja-tehokkuus/kustannukset/liikenteen-ulkoiset-kustannukset/"
              target="_blank"
            >
              Liikenne- ja viestintäministeriön mukaan
            </a>{" "}
            puoli miljardia vuodessa
          </>
        }
      ></Section>
      <Section
        id="nettomaksu4"
        nextId="kunnat"
        showTitleDesc
        title={
          <>
            <Countdown from={1.8} to={1.3} inMs={800} shortDelay /> miljardia
          </>
        }
      />
      <Section
        id="kunnat"
        nextId="nettomaksu5"
        title="Kunnat"
        description={
          <>
            Valtio hoitaa vain pääteitä. Kunnat rakentavat omalla rahallaan
            tiestönsä. Kunnat rahoittavat tiestönsä kunnallisverolla. Vuonna
            2014 kunnat käyttivät tienpitoon n.{" "}
            <a
              href="http://www.stat.fi/til/ktt/2014/ktt_2014_2015-11-20_fi.pdf"
              target="_blank"
            >
              700 miljoonaa euroa
            </a>
            . Tästä arviolta 70%, eli <b>puoli miljardia</b> menee autoiluun.
          </>
        }
      ></Section>
      <Section
        id="nettomaksu5"
        nextId="melu"
        showTitleDesc
        title={
          <>
            <Countdown from={1.3} to={0.8} inMs={800} shortDelay /> miljardia
          </>
        }
      />
      <Section
        id="melu"
        nextId="nettomaksu6"
        title="Melu"
        description={
          <>
            Helsingin asukkaiden mukaan tieliikennemelu on häiritsevin
            melumuoto.{" "}
            <a
              href="https://www.julkari.fi/bitstream/handle/10024/135521/Reinikainen%20ym.%202017%20LIMETKU_nettiversio%20%28tp%2037%29.pdf?sequence=1&isAllowed=y"
              target="_blank"
            >
              34 prosenttia Helsinkiläisistä
            </a>{" "}
            kokee tieliikennemelun häiritseväksi tai erittäin häiritseväksi
            päivisin ja 23 prosenttia öisin. Lähes puolet asukkaista koki melun
            häiritsevän häiritsevän nukkumista, parvekkeella tai pihalla oloa,
            rentoutumista ja ulkoilua virkistysalueilla. <br />
            <br />
            <cite>
              <a
                style={{ whiteSpace: "initial" }}
                href="https://www.julkari.fi/bitstream/handle/10024/135521/Reinikainen%20ym.%202017%20LIMETKU_nettiversio%20%28tp%2037%29.pdf?sequence=1&isAllowed=y"
              >
                Ruotsissa väestön melunaltistuksen on arvioitu aiheuttavan
                vuosittain 1,7 miljardin euron kustannusvaikutukset
                yhteiskunnalle
              </a>
            </cite>{" "}
            Oletetaan, että Suomessa kustannukset ovat henkeä kohden samat kuin
            Ruotsissa, ja oletetaan että 50% tästä liittyy liikenteeseen, sillä
            liikennemelu on suurin melunlähde. Kustannukset <b>0.4 miljardia</b>
          </>
        }
      ></Section>
      <Section
        id="nettomaksu6"
        nextId="matkakuluvähennys"
        showTitleDesc
        title={
          <>
            <Countdown from={0.8} to={0.4} inMs={800} shortDelay /> miljardia
          </>
        }
      />
      <Section
        id="matkakuluvähennys"
        nextId="matkakuluvähennys2"
        title="Matkakuluvähennys"
        description={
          <>
            Autoilun matkakuluista vähennetään verotuksessa{" "}
            <a
              style={{ whiteSpace: "inherit" }}
              href="http://www.ym.fi/download/YMra132013_Ympariston_kannalta_haitalliset_tuet/b3e047cc-dd7a-4897-ba56-513fbdc50c5f/40297"
            >
              ympäristöministeriön selvityksen mukaan
            </a>{" "}
            puoli miljardia vuosittain.
          </>
        }
      ></Section>
      <Section
        id="matkakuluvähennys2"
        nextId="nettomaksu7"
        description={
          <>
            Autoliiton{" "}
            <a href="https://www.tekniikkatalous.fi/uutiset/tamperelaisaktivisti-laski-autoilun-kulut-ja-vaittaa-autoilijat-maksavat-vahemman-kuin-saavat/25c6c42c-5682-4d28-b6df-47ec73c4a280?ref=twitter%3A0101">
              toimitusjohtajan mukaan
            </a>{" "}
            suurin osa matkakuluvähennyksestä menee julkisen liikenteen
            käyttäjille.
            <br />
            <br />
            <cite>
              ”Suurin osahan tuosta vähennyksestä menee julkiseen liikenteen
              käyttäjille tai korvataan sen taksojen mukaan.”
            </cite>
            <br />
            <br />
            <b>
              Henkilöautoilijoiden osuus kaikista vähennysten saajista oli 63 %.
              Euromääräisesti vastaava osuus oli noin 80 %. Tutustu laskelmiin{" "}
              <a href="http://www.ym.fi/download/YMra132013_Ympariston_kannalta_haitalliset_tuet/b3e047cc-dd7a-4897-ba56-513fbdc50c5f/40297">
                täällä
              </a>
              .
            </b>
          </>
        }
      ></Section>
      <Section
        id="nettomaksu7"
        nextId="autoliitto-cherry-picking"
        showTitleDesc
        title={
          <>
            <Countdown from={0.4} to={-0.1} inMs={800} shortDelay /> miljardia
          </>
        }
        description={
          <>
            Näyttää, että autoilusta aiheutuvat kustannukset söivät autoilusta
            maksestut erikoisverot pois. Autoilu on siis verotukseltaan samalla
            viivalla muiden liiketoimintasektoreitten kanssa.
            <br />
            <br />
            Onko sinusta reilua, että haitat rahoitetaan "rankaisuveroilla"?
          </>
        }
      />
      <Section
        id="autoliitto-cherry-picking"
        nextId="asuminen"
        description={
          <>
            Autoilusta tulee voida keskustella faktapohjaisesti. Autoliiton
            väite, että autoilija maksaa 8 miljardia ja saa takaisin miljardin
            tienpitoon, on erittäin laajalle levinnyt, ja luonut kuvaa erittäin
            epäoikeudenmukaisesta verotuksesta. Suorastaan rankaisemisesta.
            <br />
            <br />
            Väite on totta. Mutta väitteessä sivuutetaan täysin autoilun
            haittakustannukset. Tämä sivu osoitti, että niitä on, ja ne ovat
            suuria. Itse asiassa yhtä suuria kuin autoilun "rankaisuverot".
            Sivun laskelmat eivät varmasti ole täydellisiä. Ne kuitenkin tuovat
            ilmi, miten yksioikoista autoilun verotuksesta keskusteleminen on
            <br />
            <br />
            Autoilun haitat keskittyvät etenkin kaupunkiseuduille. Jatketaan
            kaupungeista
          </>
        }
      />
      <Section
        id="asuminen"
        nextId="kaupungit"
        title="Asumisen hinta"
        description={
          <>
            Parkkinormien vuoksi esimerkiksi Helsingin kantakaupungissa
            autopaikan rakennuskustannukset voivat olla jopa{" "}
            <a
              href="https://www.rakennuslehti.fi/2015/12/yhden-pysakointipaikan-rakennuskustannukset-voivat-olla-jopa-70%E2%80%89000-euroa/"
              target="_blank"
            >
              70 000 euroa paikkaa kohti
            </a>
            . Paikat eivät mene tuolla hinnalla kaupaksi, joten paikoista tullut
            tappio leivotaan asuntojen hintoihin. Asumisen hintaa nostaa myös
            melumittaukset sekä melueristäminen. Melu rajoittaa
            huoneistosijoittelua kaupunkiasunnoissa. Kaupunkiasunnot ovat
            autoliikenteen melun takia rajoittuneemmin suunniteltuja.
            <br />
            <br />
            Rakennusteollisuus vastustaa parkkinormia. Paikkoja ei rakenneta
            markkinavetoisesti, vaan valtiovetoisesti.
          </>
        }
      ></Section>
      <Section
        id="kaupungit"
        nextId="loppu"
        description={
          <>
            Autoilun haitat keskittyvät kaupunkeihin. Etenkin kaupungeissa
            autoilun vaatima tila, sen aiheuttama melu sekä turvattomuus
            korostuvat. Tälle on vaikea asettaa hintaa, mutta:
            <br />
            <br />
            <b>
              Jokainen tietää miten viihtyisää 50-luvulla ja sitä ennen
              rakennettu kaupunkiympäristö on. Ja miten epäviihtyisää 70 luvulla
              ja myöhemmin autoilun ehdoilla rakennettu kaupunkiympäristö on.
            </b>
          </>
        }
      />
      <Section
        id="loppu"
        title="Kaupunkilainen!"
        description="Vaadi viihtyisämpää kaupunkia. Et ole velkaa autoilijalle."
      >
        <div className="flex">
          <div className="mr-4">
            <a
              className="twitter-share-button"
              href="https://twitter.com/intent/tweet"
              data-size="large"
            >
              Tweet
            </a>
          </div>
          <div
            className="fb-share-button"
            data-href="http://kahdeksan.miljardia.info"
            data-layout="button"
            data-size="large"
          >
            <a
              target="_blank"
              href="https://www.facebook.com/sharer/sharer.php?u=http%3A%2F%2Fkahdeksan.miljardia.info%2F&amp;src=sdkpreparse"
              className="fb-xfbml-parse-ignore"
            >
              Jaa
            </a>
          </div>
        </div>
      </Section>
      <Section id="tekija" nextId="lahdeluettelo">
        <p className="text-gray-500 font-serif text-xs pt-4 text-center pb-4">
          Tämän sivuston on rakentanut yksityishenkilö jota ärsyttää Autoliiton{" "}
          <a
            target="_blank"
            href="https://www.autoliitto.fi/tietoa-tienkayttajalle/vaalit"
          >
            tietoinen harhaanjohtaminen
          </a>{" "}
          autoilun veroista ja kustannuksista.
          <br />
          <br />
          Tavoitat sivuston tekijän{" "}
          <a target="_blank" href="https://twitter.com/laurinevanpera">
            Twitteristä
          </a>
          .
          <br />
          <br />
          Sivuston tekijä on maatalon poika, ja toiminut
          kuorma-autonkuljettajana sekä traktorikuljettajana ennen uraa
          ohjemistoalalla. Sivuston tekijä tietää miten tärkeää autoilu on
          yhteiskunnalle. Sivuston tekijän mielestä kuitenkin myös autoilun
          aiheuttamista kustannuksista tulee puhua.
        </p>
      </Section>
      <Section id="lahdeluettelo" nextId="ulkoishyodyt">
        <p className="text-gray-500 font-serif text-xs pt-4 text-left">
          Tällä sivustolla ei käsitellä seuraavia aiheita. Lue seuraavalla
          sivulla miksi.
          <ul className="list-disc pl-3">
            <li>Autoilun hyödyt yhteiskunnalle.</li>
            <li>
              Liikkumattomuuden{" "}
              <a
                href="https://www.ukkinstituutti.fi/tutkimus/liikuntatutkimus_suomessa/liikkumattomuuden-kustannukset"
                target="_blank"
              >
                aiheuttamat kustannukset
              </a>{" "}
              yhteiskunnalle (3.2 - 7 miljardia)
            </li>
            <li>
              Henkilöautoilun negatiivinen vaikutus valtion kauppataseeseen
            </li>
            <li>Ilmastovaikutukset</li>
          </ul>
          <br />
          <br />
          Lähdeluettelo
          <ul>
            <li>
              <a
                href="https://www.autoliitto.fi/tietoa-tienkayttajalle/vaalit"
                target="_blank"
                style={{ whiteSpace: "initial" }}
              >
                Autoliiton vaalivaikutus-sivu, jossa väite 8 vs 1 miljardia
              </a>
            </li>
            <li>
              <a
                href="http://www.aut.fi/etusivu_vanha/tilastot/verotus_ja_hintakehitys/valtion_verotulot_tieliikenteesta"
                target="_blank"
              >
                Autoalan tiedotuskeskuksen verolaskelma
              </a>
            </li>
            <li>
              <a
                target="_blank"
                href="http://liikennejarjestelma.fi/talous-ja-tehokkuus/kustannukset/liikenteen-ulkoiset-kustannukset/"
              >
                Kuolemat, loukkaantumiset, sairastumiset
              </a>
            </li>
            <li>
              <a
                href="http://www.stat.fi/til/ktt/2014/ktt_2014_2015-11-20_fi.pdf"
                target="_blank"
              >
                Kuntien tienpitoon laittama raha
              </a>
            </li>
            <li>
              <a
                href="https://www.julkari.fi/bitstream/handle/10024/135521/Reinikainen%20ym.%202017%20LIMETKU_nettiversio%20%28tp%2037%29.pdf?sequence=1&isAllowed=y"
                target="_blank"
              >
                Tutkimus Helsinkiläisten kokemasta melusta
              </a>
            </li>
            <li>
              <a
                href="https://www.rakennuslehti.fi/2015/12/yhden-pysakointipaikan-rakennuskustannukset-voivat-olla-jopa-70%E2%80%89000-euroa/"
                target="_blank"
              >
                Pysäköintipaikan rakennuskustannukset
              </a>
            </li>
            <li>
              <a href="http://www.ym.fi/download/YMra132013_Ympariston_kannalta_haitalliset_tuet/b3e047cc-dd7a-4897-ba56-513fbdc50c5f/40297">
                Matkakulut
              </a>
            </li>
          </ul>
        </p>
      </Section>
      <Section
        id="ulkoishyodyt"
        nextId="ulkoishyodyt2"
        title="Ulkoishyödyt"
        description={
          <>
            <cite>
              Miksei autoilun hyötyjä ole laskuissa mukana? Yhteiskunta pysähtyy
              ilman autoja.
            </cite>
            <br />
            <br />
            Autoilusta on totta kai äärettömän paljon ulkoishyötyjä.
            Ulkoishyötyjen laskeminen olisi erittäin mielenkiintoista, mutta
            valitettavasti mahdotonta.
            <br />
            <br />
            Perkaaminen pitäisi aloittaa kysymyksestä: "Entä jos Suomen
            henkilöliikkumista ei olisikaan rakennettu 70-luvulta lähtien
            henkilöautoilun varaan?" Nykyisen kaltainen väljästi asutettu
            autoiluyhteiskunta ja kaupunkirakenne on vain yksi monista
            mahdollisista yhdyskuntamalleista. Entä jos autoilua olisi 30%
            vähemmän? Näkyisikö tämä valtion kauppataseessa ja kansalaisten
            hyvinvoinnissa positiivisesti?
          </>
        }
      ></Section>
      <Section
        id="ulkoishyodyt2"
        description={
          <>
            Kuulostaa äärimmäisen huonolta taloudelliselta päätökseltä rakentaa
            yhteiskunnan henkilöliikkuminen ulkomailta ostetun, 15 - 100 tuhatta
            euroa maksavan auton varaan. Auto syö pääosin ulkomaista
            polttoainetta, käyttää ulkomaisia varaosia, kuluttaa pääosin
            ulkomaisia renkaita, ja se laitetaan paaliin suhteellisen lyhyen
            käyttöikänsä päätteeksi.
            <br />
            <br />
            Ulkoishyötyjen laskeminen olisi todella syvälle menevää
            spekulointia, ja tästä syystä niitä ei tällä sivustolla ole.
          </>
        }
      ></Section>
    </AppStyles>
  );
}

export default App;
